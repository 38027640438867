import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SVG from "react-inlinesvg"

import Menu from "../Menu/Menu"
import FooterMenu from "../Menu/FooterMenu"
import Button from "../UI/Button/Button"
import FooterAcceptPayment from "./components/FooterAcceptPayment"

import "./Footer.scss"
import InstagramSVG from "../../images/icons/instagram.svg"
// import BehanceSVG from "../../images/icons/behance.svg"
import LinkedInSVG from "../../images/icons/linkedin.svg"
import FacebookSVG from "../../images/icons/facebook.svg"
import XSVG from "../../images/icons/icon-x.svg"
import WhatsappSVG from "../../images/icons/whatsapp.svg"
import PinterestSVG from "../../images/icons/pinterest.svg"
import ThreadsSVG from "../../images/icons/threads.svg"
import TikTokSVG from "../../images/icons/tiktok.svg"
import TelegramSVG from "../../images/icons/telegram.svg"
import InputButtonSVG from "../../images/icons/inputbutton.svg"
import useDomainName from "../../hook/useDomainName"
import FooterCopyright from "./components/FooterCopyright"
import { useAppLanguage } from "../../hook/useAppLanguage"

const Footer = () => {
  const [sendingSubscribe, setSendingSubscribe] = useState(false)
  const [isSubscribe, setIsSubscribe] = useState(false)
  const [subscribeError, setSubscribeError] = useState(false)
  const [email, setEmail] = useState("")
  const [subscribeTitle, setSubscribeTitle] = useState(
    "Subscribe and work with us towards your business improvement:",
  )
  const { t } = useTranslation()
  const { isUSASite, hostName, isMainSite } = useDomainName()
  const locale = useAppLanguage()

  const handleSubmit = e => {
    e.preventDefault()
    setSendingSubscribe(true)

    // TODO: анимация, прелоадоры
    fetch(process.env.GATSBY_API_URL + "subscribe", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        domain: hostName,
        source: "Subscribe in the footer",
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then(res => {
        setSendingSubscribe(false)
        return res.clone().json()
      })
      .then(response => {
        if (response.success) {
          setEmail("")
          setIsSubscribe(true)
        } else {
          throw new Error("Unknown error. Try later.")
        }
      })
      .catch(error => {
        setSubscribeTitle(
          `${t(
            "Unknown error. Try later or contact us by mail:",
          )} ${process.env.GATSBY_CONTACT_EMAIL}`,
        )
        setSubscribeError(true)
        setTimeout(() => {
          setSendingSubscribe(false)
          setSubscribeTitle(
            "Subscribe and work with us towards your business improvement:",
          )
          setSubscribeError(false)
        }, 1500)
      })
  }

  return (
    <footer>
      <div className="footer__menus">
        <Menu footer="true" />
        <FooterMenu />
      </div>
      <div
        className={`right-side footer__right-side ${
          locale === "ru" && "lang_ru"
        }`}
      >
        {!isSubscribe ? (
          <form
            className={
              "work-with-us" +
              (sendingSubscribe ? " work-with-us_sending" : "") +
              (subscribeError ? " work-with-us_error" : "")
            }
            onSubmit={handleSubmit}
          >
            <span className="work-with-us__label">
              {t(subscribeTitle, {
                keySeparator: ">",
                nsSeparator: "|",
              })}
            </span>
            <div className="get-form">
              <input
                onChange={e => setEmail(e.target.value)}
                required
                type="email"
                name="email"
                disabled={sendingSubscribe ? "disabled" : ""}
                placeholder={t("Your e-mail")}
                className="input__text work-with-us__input"
                value={email}
              />
              <Button disabled={sendingSubscribe ? "disabled" : ""} typeInput>
                <SVG src={InputButtonSVG} />
              </Button>
            </div>
          </form>
        ) : (
          <div className="subscribe-status">
            {t("Thanks! You have successfully subscribed to the newsletter.")}
          </div>
        )}

        <div className="social_buttons">
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Instagram"
            href="https://www.instagram.com/usava.team/"
          >
            <SVG src={InstagramSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
            href="https://www.linkedin.com/company/usava-team/"
          >
            <SVG src={LinkedInSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="X"
            href="https://x.com/usava_team"
          >
            <SVG src={XSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Telegram"
            href={"https://t.me/usavateam"}
            // href={`https://t.me/${isUSASite ? "u" : ""}sava_team`}
          >
            <SVG src={TelegramSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="WhatsApp"
            href={`https://wa.me/${isUSASite ? "18475046041" : "995571168833"}`}
          >
            <SVG src={WhatsappSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Pinterest"
            href="https://pinterest.com/usava_team/"
          >
            <SVG src={PinterestSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
            href="https://facebook.com/usava.team"
          >
            <SVG src={FacebookSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="TikTok"
            href="https://www.tiktok.com/@usava.team"
          >
            <SVG src={TikTokSVG} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Threads"
            href="https://www.threads.net/@usava.team"
          >
            <SVG src={ThreadsSVG} />
          </a>
        </div>
        <FooterAcceptPayment />
        <FooterCopyright />
      </div>
    </footer>
  )
}

export default Footer
