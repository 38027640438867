exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-portfolio-portfolio-page-js": () => import("./../../../src/components/Portfolio/PortfolioPage.js" /* webpackChunkName: "component---src-components-portfolio-portfolio-page-js" */),
  "component---src-dynamic-pages-requisites-index-jsx": () => import("./../../../src/dynamicPages/requisites/index.jsx" /* webpackChunkName: "component---src-dynamic-pages-requisites-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-methods-js": () => import("./../../../src/pages/payment-methods.js" /* webpackChunkName: "component---src-pages-payment-methods-js" */),
  "component---src-pages-personal-data-processing-policy-js": () => import("./../../../src/pages/personal-data-processing-policy.js" /* webpackChunkName: "component---src-pages-personal-data-processing-policy-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-quest-1-js": () => import("./../../../src/pages/quest-1.js" /* webpackChunkName: "component---src-pages-quest-1-js" */),
  "component---src-pages-sales-summer-js": () => import("./../../../src/pages/sales/summer.js" /* webpackChunkName: "component---src-pages-sales-summer-js" */),
  "component---src-pages-services-artificial-intelligence-ai-accelerated-engineering-js": () => import("./../../../src/pages/services/artificial-intelligence/ai-accelerated-engineering.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-ai-accelerated-engineering-js" */),
  "component---src-pages-services-artificial-intelligence-ai-security-by-design-js": () => import("./../../../src/pages/services/artificial-intelligence/ai-security-by-design.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-ai-security-by-design-js" */),
  "component---src-pages-services-artificial-intelligence-generative-ai-js": () => import("./../../../src/pages/services/artificial-intelligence/generative-ai.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-generative-ai-js" */),
  "component---src-pages-services-artificial-intelligence-responsible-ai-js": () => import("./../../../src/pages/services/artificial-intelligence/responsible-ai.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-responsible-ai-js" */),
  "component---src-pages-services-customer-experience-cx-strategy-and-design-js": () => import("./../../../src/pages/services/customer-experience/cx-strategy-and-design.js" /* webpackChunkName: "component---src-pages-services-customer-experience-cx-strategy-and-design-js" */),
  "component---src-pages-services-customer-experience-digital-transformation-js": () => import("./../../../src/pages/services/customer-experience/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-customer-experience-digital-transformation-js" */),
  "component---src-pages-services-customer-experience-next-gen-marketing-js": () => import("./../../../src/pages/services/customer-experience/next-gen-marketing.js" /* webpackChunkName: "component---src-pages-services-customer-experience-next-gen-marketing-js" */),
  "component---src-pages-services-customer-experience-physical-experience-js": () => import("./../../../src/pages/services/customer-experience/physical-experience.js" /* webpackChunkName: "component---src-pages-services-customer-experience-physical-experience-js" */),
  "component---src-pages-services-customer-experience-white-space-innovation-js": () => import("./../../../src/pages/services/customer-experience/white-space-innovation.js" /* webpackChunkName: "component---src-pages-services-customer-experience-white-space-innovation-js" */),
  "component---src-pages-services-customer-experience-workforce-experience-js": () => import("./../../../src/pages/services/customer-experience/workforce-experience.js" /* webpackChunkName: "component---src-pages-services-customer-experience-workforce-experience-js" */),
  "component---src-pages-services-cybersecurity-cloud-and-data-security-js": () => import("./../../../src/pages/services/cybersecurity/cloud-and-data-security.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-cloud-and-data-security-js" */),
  "component---src-pages-services-cybersecurity-cybersecurity-advisory-js": () => import("./../../../src/pages/services/cybersecurity/cybersecurity-advisory.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-cybersecurity-advisory-js" */),
  "component---src-pages-services-cybersecurity-digital-risk-management-js": () => import("./../../../src/pages/services/cybersecurity/digital-risk-management.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-digital-risk-management-js" */),
  "component---src-pages-services-cybersecurity-managed-detection-and-response-js": () => import("./../../../src/pages/services/cybersecurity/managed-detection-and-response.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-managed-detection-and-response-js" */),
  "component---src-pages-services-cybersecurity-outside-in-validation-js": () => import("./../../../src/pages/services/cybersecurity/outside-in-validation.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-outside-in-validation-js" */),
  "component---src-pages-services-cybersecurity-ransomware-protection-js": () => import("./../../../src/pages/services/cybersecurity/ransomware-protection.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-ransomware-protection-js" */),
  "component---src-pages-services-cybersecurity-zero-trust-implementation-js": () => import("./../../../src/pages/services/cybersecurity/zero-trust-implementation.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-zero-trust-implementation-js" */),
  "component---src-pages-services-data-and-analytics-cloud-data-migration-js": () => import("./../../../src/pages/services/data-and-analytics/cloud-data-migration.js" /* webpackChunkName: "component---src-pages-services-data-and-analytics-cloud-data-migration-js" */),
  "component---src-pages-services-engineering-agile-ways-of-working-js": () => import("./../../../src/pages/services/engineering/agile-ways-of-working.js" /* webpackChunkName: "component---src-pages-services-engineering-agile-ways-of-working-js" */),
  "component---src-pages-services-engineering-api-and-integration-js": () => import("./../../../src/pages/services/engineering/api-and-integration.js" /* webpackChunkName: "component---src-pages-services-engineering-api-and-integration-js" */),
  "component---src-pages-services-engineering-core-and-enterprise-systems-js": () => import("./../../../src/pages/services/engineering/core-and-enterprise-systems.js" /* webpackChunkName: "component---src-pages-services-engineering-core-and-enterprise-systems-js" */),
  "component---src-pages-services-engineering-devops-js": () => import("./../../../src/pages/services/engineering/devops.js" /* webpackChunkName: "component---src-pages-services-engineering-devops-js" */),
  "component---src-pages-services-engineering-iot-js": () => import("./../../../src/pages/services/engineering/iot.js" /* webpackChunkName: "component---src-pages-services-engineering-iot-js" */),
  "component---src-pages-services-engineering-mach-js": () => import("./../../../src/pages/services/engineering/mach.js" /* webpackChunkName: "component---src-pages-services-engineering-mach-js" */),
  "component---src-pages-services-engineering-metaverse-js": () => import("./../../../src/pages/services/engineering/metaverse.js" /* webpackChunkName: "component---src-pages-services-engineering-metaverse-js" */),
  "component---src-pages-services-engineering-modernization-js": () => import("./../../../src/pages/services/engineering/modernization.js" /* webpackChunkName: "component---src-pages-services-engineering-modernization-js" */),
  "component---src-pages-services-engineering-open-source-js": () => import("./../../../src/pages/services/engineering/open-source.js" /* webpackChunkName: "component---src-pages-services-engineering-open-source-js" */),
  "component---src-pages-services-engineering-physical-product-development-js": () => import("./../../../src/pages/services/engineering/physical-product-development.js" /* webpackChunkName: "component---src-pages-services-engineering-physical-product-development-js" */),
  "component---src-pages-services-engineering-platform-and-product-development-js": () => import("./../../../src/pages/services/engineering/platform-and-product-development.js" /* webpackChunkName: "component---src-pages-services-engineering-platform-and-product-development-js" */),
  "component---src-pages-services-engineering-quality-engineering-js": () => import("./../../../src/pages/services/engineering/quality-engineering.js" /* webpackChunkName: "component---src-pages-services-engineering-quality-engineering-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-strategy-generative-ai-advisory-js": () => import("./../../../src/pages/services/strategy/generative-ai-advisory.js" /* webpackChunkName: "component---src-pages-services-strategy-generative-ai-advisory-js" */),
  "component---src-pages-services-strategy-mergers-and-acquisitions-js": () => import("./../../../src/pages/services/strategy/mergers-and-acquisitions.js" /* webpackChunkName: "component---src-pages-services-strategy-mergers-and-acquisitions-js" */),
  "component---src-pages-services-strategy-optimizing-for-growth-js": () => import("./../../../src/pages/services/strategy/optimizing-for-growth.js" /* webpackChunkName: "component---src-pages-services-strategy-optimizing-for-growth-js" */),
  "component---src-pages-services-strategy-project-to-product-js": () => import("./../../../src/pages/services/strategy/project-to-product.js" /* webpackChunkName: "component---src-pages-services-strategy-project-to-product-js" */),
  "component---src-pages-services-strategy-transformative-research-and-insights-js": () => import("./../../../src/pages/services/strategy/transformative-research-and-insights.js" /* webpackChunkName: "component---src-pages-services-strategy-transformative-research-and-insights-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

